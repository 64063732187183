import React, {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Button, Drawer, FlexboxGrid, IconButton, Nav, Placeholder} from "rsuite"
import EntryEditorSimple from "./editor-simple"
import EntryEditorComplex from "./editor-complex"
import useAccountingStyles from "../styles"
import {selectContext} from "../store/entries-slice"
import {closeEditor, selectBooking, selectBookingId, selectEditorDrawer, selectTab, setTab} from "../store/editor-slice"
import {useAccountingBookingActionMutation, useSaveAccountingBookingMutation} from "../store/accounting-api";
import SioIcon from "../../icon/rsuite-icon-font/SioIcon"

import {selectOrganization, selectStack} from "../store/entries-slice"
import {selectCurrentPeriod} from "../store/state-slice"
import {newBooking} from "../store/editor-slice"

/**
 * @type {{[tab: EditorTab]: [label: string, component: ReactElement]}}
 */
const TABS = Object.freeze({
    simple: ["Buchung", EntryEditorSimple],
    complex: ["Splitbuchung", EntryEditorComplex]
});

export default function EntryEditor() {
    const dispatch = useDispatch()
    const context = useSelector(selectContext)
    const id = useSelector(selectBookingId)
    const [open, loading] = useSelector(selectEditorDrawer)
    const tab = useSelector(selectTab)
    const styles = useAccountingStyles()
    const Tab = TABS[tab][1];

    return (
        <Drawer className={styles.drawer} open={open} backdrop={false} onClose={() => dispatch(closeEditor())}
                placement="bottom" size={"complex" === tab ? "md" : "sm"}>
            <Drawer.Header closeButton={false}>
                <FlexboxGrid style={{flexGrow: 1}}>
                    <FlexboxGrid.Item style={{flexGrow: 1}}>
                        {!loading && (
                            <Nav appearance="tabs">
                                {Object.entries(TABS).map(([name, [label]]) => (
                                    <Nav.Item key={name} active={name === tab}
                                              onClick={() => dispatch(setTab(name))}>{label}</Nav.Item>
                                ))}
                            </Nav>
                        )}
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item style={{width: "auto"}} className="text-right">
                        {"stack" === context ? (
                            <SaveButton id={ id ? "Speichern" : 'submit-booking'} appearance="primary" disabled={loading}>
                                {id ? "Speichern" : "Hinzufügen"}
                            </SaveButton>
                        ) : (
                            <SaveButton className={styles.danger} disabled={loading}
                                        icon={<SioIcon icon="fa fa-lock"/>}>
                                Buchen
                            </SaveButton> 
                        )}
                        <Button onClick={() => dispatch(closeEditor())}>Abbrechen</Button>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Drawer.Header>
            <Drawer.Body>
                {loading ? (
                    <Placeholder.Grid active={true}/>
                ) : (
                    <Tab/>
                )}
            </Drawer.Body>
        </Drawer>
    )
}

function SaveButton({icon, children, ...props}) {
    const id = useSelector(selectBookingId)
    const booking = useSelector(selectBooking)
    const context = useSelector(selectContext)
    const [save, {isLoading, isSuccess, data}] = useSaveAccountingBookingMutation()
    const [open] = useSelector(selectEditorDrawer)

    const [doAction] = useAccountingBookingActionMutation()
    const Element = icon ? IconButton : Button


    const dispatch = useDispatch()
    const organization = useSelector(selectOrganization)
    const stack = useSelector(selectStack)
    const period = useSelector(selectCurrentPeriod(organization))
  
    useEffect(
        () => {
            if ("account" === context && isSuccess && data?.id) {
                doAction({id: data.id, action: "book"})
            }
            if ("stack" === context && isSuccess && data?.id == undefined && props?.id == "submit-booking" ) {
                setTimeout(function() {
                    dispatch(
                        newBooking({organization, period, stack})
                    )
                }, 1000); 
            }
        },
        [isSuccess]
    )

    return (
        <Element loading={isLoading} icon={icon} onClick={() => save({id, ...booking})} {...props}>
            {children}
        </Element>
    )
}
